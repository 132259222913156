<script lang="ts">
  import Text from '../text/text.svelte';
  import type { Person } from '$lib/contentful/models/person';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import { highlightText } from '$lib/utilities/highlight-text';

  export let person: Person | undefined;
  export let author: string | undefined = undefined;
  export let highlightAuthor = false;
  export let currentQuery: string | null = null;
</script>

{#if person}
  <div class="flex gap-4">
    {#if person.photograph}
      <ContentfulImage
        src={person.photograph?.url}
        alt={person.photograph?.title || person.name}
        fitStrategy="thumb"
        focusStrategy="face"
        width={480}
        height={480}
        class="rounded-full w-12 h-12"
        pictureClass="shrink-0"
      />
    {:else}
      <span
        class="w-12 shrink-0 h-12 rounded-full bg-ultraviolet text-white flex items-center justify-center text-xl"
        >{person.name[0].toUpperCase()}</span
      >
    {/if}
    <div class="flex flex-col gap-0">
      {#if highlightAuthor && author && currentQuery}
        <RichText
          class="font-mono text-[16px] uppercase"
          theme="white"
          text={highlightText(author, currentQuery)}
        />
      {:else}
        <Text variant="eyebrow-16" class="text-white">
          {person.name}
        </Text>
      {/if}
      <Text variant="body-16" class="text-blue-gray-500">
        {person.jobTitle}
      </Text>
    </div>
  </div>
{/if}
